header .MuiButton-root, header .MuiSelect-selectMenu {
    color: #000!important;
    font-size: 18px;
    line-height: 22px;
    font-family: "Poppins", sans-serif;
    font-weight:500;    
    text-transform: none;
	  letter-spacing: 0px;
}
header .MuiSelect-selectMenu:hover {
  background-color: #2876FB !important;
  color: #fff;
}
.sidenav-logo.navbar-brand {
        margin-left: 23px;
        width: 200px;
}
header .MuiButton-root{
    margin-right: 35px;
}
header .MuiSelect-icon {
    color:#000;
}

header .MuiButton-text {
    padding: 0;
}
header .help-button{
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    margin-right: 32px;
    color:#000;
}
.header-menu {
    display: flex;
    margin-left: auto;
    align-items: center;
}
.wallet-btn a {
    color: #fff !important;
    background: #2876FB;
    padding: 10px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight:normal;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Poppins", sans-serif;
    min-width: 145px;
}
header .MuiButton-label{
    justify-content: left;
}
header .MuiButton-root{
    min-width: auto;
}
.main {
    margin-top: 79px;
    padding: 0 0px 0 0;
    /* margin-bottom: 25px; */
    margin-left: 54px;
    position: relative;
    z-index: 1;
    width: calc(100% - 54px);
}
.sidenav-list {
  padding-top: 40px;
}
.sidenav-list a{
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #ffffff;
    margin-left: -16px;
    line-height: 31px;
    padding-top: 2px;
}
.sidenav-list img {
    margin-right:16px !important;
}
.sidenav-list .MuiListItem-gutters{
    padding-left: 0px;
    padding-right: 0px;
}
.navbar-brand .logo-text {
    color: #FDDA00;
}
header{
    padding: 7px 0;
    box-shadow: none !important;
}
.MuiDrawer-docked .MuiIconButton-root{
    color: red;;
}
.MuiDrawer-paperAnchorDockedLeft{
    border: none !important;
    background: linear-gradient(172.72deg, #43A2F8 1.68%, #2571FC 100.58%);
}
.avtar-img {
  background-color: #2876FB;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width:767px){
    .main {
        width: 95%;
    }
}
@media (min-width: 600px)
{
    .MuiToolbar-gutters {
        padding-left: 13px !important;
        padding-right: 20px !important;
    }
}
.menu-bar {
    margin-top: 12px;
    text-align: center;
    width: 60px;
    height: 45px;
}
.menu-bar i {
    color: #ffffff;
    font-size: 25px;
    background:#2876FB;
    padding: 7px;
    border-radius: 6px;
    font-size: 20px;
    margin-top: 7px;
}
.menu-bar p{
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
}
.white_img{
    display: none;
}

/* .makeStyles-drawerOpen-7 .sidenav-list>div img{
   
   margin-right: 5px;
} */
/* .makeStyles-drawerOpen-7 .sidenav-list > div:hover img,.jss7 .sidenav-list > div:hover img{
    margin-left: 10px;
} */
.sidenav-list>div:hover .white_img{
    display: block;
}
.sidenav-list>div:hover .yellow_img{
    display: none;
}
.sidenav-list .MuiListItem-root {
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid transparent;
    border-radius: 33px;
    margin: 7px 0;
}
.sidenav-list .MuiListItem-root:first-child {
  margin-top: 30px;
}
/* .sidenav-list > div:hover{
	background-image:linear-gradient(270deg,#E9AC1D 0% ,#F76B1C 100%);
} */
.sidenav-list > div:hover a{
    color: #1d4fee !important;
    display: block;
    padding: 0 !important;
}
.sidenav-list.MuiList-padding {
    padding: 10px 15px;
}
.makeStyles-drawerOpen-7 .sidenav-list.MuiList-padding, .jss7 .sidenav-list.MuiList-padding{
    padding: 10px 0px;
}
.makeStyles-drawerOpen-7 .sidenav-list .MuiListItem-root, .jss7 .sidenav-list .MuiListItem-root {
    padding-left:15px;
	padding-right:15px;
    border-radius: 0px;
}
.card-image{
    width: 45px !important;
    margin: 0 !important;
}
.navbar-brand {
    margin-left: 0;
    width: 270px;
}
.navbar-brand .logo-text {
    font-size: 27px;
}
.MuiDrawer-paper{
    transition: all 0.1s ease-in-out !important;
    top: 78px;
}
.makeStyles-drawerOpen-7,.jss7{
    width: 370px !important;
    overflow-x: hidden;
}
.content-section {
    /* background-color: #D8D8D8; */
    /* background-image: url("../../../../assets/images/bg.jpg"); */
    border-radius: 33px;
    padding: 30px 10px;
    background-position: 10% 10%;
}
.breadcrumbs_text{
    color: #B9540E;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}
.content-section h2{
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: #262321;
    font-size: 30px;
    line-height: 40px;
}
.content-section h2 i{
    font-size: 20px;
    color: #B9540E;
}
.card-header {
  font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: rgba(0,0,0,0.87);
    background: transparent !important;
    padding: 10px 23px !important;
}
.primary_button.btn-default {
  font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    background: #2876FB !important;
    box-shadow: 0px 0px 10px 0px #00000040;
    padding: 10px 27px;
    border-radius: 10px;
    text-transform: capitalize;
    white-space: nowrap;
}
.secondary_button.btn-default {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #B9540E;
    background-color:transparent !important;
    padding: 10px 27px;
    border-radius: 33px;
    text-transform: capitalize;
    border: 1px solid #F4751C;
    white-space: nowrap;
}
.card_content {
    min-height: 235px;
    padding: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.primary_button.btn-default:hover{
    background-color:#2959c9 !important;  
}
.secondary_button.btn-default:hover{
    background-color: #d45b06 !important;  
    color: #fff;
}
.custom_container{
    max-width: 100% !important;
}
@media(max-width:991px){
    header .MuiButton-root,header .help-button {
        margin-right: 27px;
    }
}
.language_change ul li{
    color: #fff;
}
.MuiMenu-paper {
    margin-top: 42px !important;
}
.MuiMenu-paper .MuiMenuItem-root {
    color: #3c3c3c;
    font-size: 14px;
}
.MuiInput-underline:after,.MuiInput-underline:before{
    display: none !important;
}

header .MuiButton-root:hover, header .MuiSelect-selectMenu:hover{
  color: #794fee !important;
}
.right_side_nav{
    display: none;
}
.right_side_nav p{
    position: fixed;
    right: 30px;
    top: 5px;
    color: #2a6aff;
    font-size: 39px;
    line-height: 1;
    z-index: 99;
}
.menu-bar{
    display: none;
}
#drawer.pin .MuiDrawer-paper{
    width: 340px;
}
.dapp-footer-wrap {
  background: #d8e3fd;
  padding: 50px 0 10px;
  margin-top: -24px;
}
.dapp-footer-wrap a i {
  color: #2b6aff !important;
}
@media(min-width:599px) {
  .makeStyles-drawerClose-8, .jss8 {
    width: 54px !important;
  }
}
@media(min-width:768px) {
  .MuiDrawer-paperAnchorLeft {
    top: 79px !important;
  }
}
@media(max-width:767px){
    .menu-bar {
        display: block;
        position: fixed;
        z-index: 12365;
        left: 0;
        top: 0;
    }
    .MuiDrawer-paper.MuiDrawer-paperAnchorLeft{
        width: 0 !important;
        left: -100px;
    }
    #drawer.show .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        width: 75% !important;
        left: 0px;
        padding: 15px 0px;
    }
	.sidenav-logo.navbar-brand{
		margin-left: 20px;
	}
    .main {
        margin: 79px 10px;
    }
   
}
/* light mode css */

.light .MuiPaper-root {
    background-image: none;
}
.light header .MuiButton-root,.light header .MuiSelect-selectMenu,.light header .MuiSelect-icon,
.light header .help-button,
.light header .MuiSvgIcon-root,.light .menu-bar p ,.light .MuiMenu-paper .MuiMenuItem-root{
    color: #000 !important;
}
.MuiSvgIcon-root {
  fill: #1d4fee;
}

@media(max-width:767px){
  
    .content-section {
        padding: 22px 12px;
    }
    .navbar-brand {
        width: 205px;
    }
    .sidenav-list.MuiList-padding {
        padding: 0 10px;
    }
    .header-menu{
        display: none;
    }
    #header.show .header-menu {
        background:#ffffff;
        height: 100vh;
        width: 53%;
        position: fixed;
        display: block;
        top: 0;
        right: 0;
        padding: 78px 16px;
    }
    .sidenav-logo.navbar-brand {
        margin-left: 40px;
        margin-top: 0;
    }
    header .help-button{
        margin-top: 20px;
    }
    header .MuiButton-root,header .MuiButton-root, header .help-button {
        margin-bottom: 20px;
    }
    .avtar-img svg {
        font-size: 27px;
    }
    .makeStyles-drawerClose-8, .jss8 {
        width: 54px !important;
    }
    .right_side_nav{
        display: block;
    }
    .light #header.show .header-menu {
        background: #fff;
    }
    
}
