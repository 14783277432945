/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  background-color: #ffffff !important;
}
#root {
  height: 100vh;
}
body > iframe[style*='2147483647']{
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fs-16 {
  font-size: 16px;
}
.fs-24 {
  font-size: 24px;
}

.homeFont {
  font-family: "Poppins", sans-serif !important;
}
.home-title-font {
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  font-size: 48px;
  font-weight:bold;
}
.home-title-font.sm {
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize;
  font-size: 36px;
  font-weight:bold;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.yellow-text{
  color: #2a6aff;
}
.orange-text{
  color: #ffffff;
}
.gray-text{
  color: #b3b3b3;
}
.text-pink{
	color:#2a6aff;
}
.black-color {
  color: #000000;
}
.primary-color {
  color: #2876FB;
}
.h-100 {
  height: 100vh;
}
.underline{
  text-decoration: underline;
}
.thin-text, .text-din{
font-family: "Poppins", sans-serif;
  font-weight: normal;
}
.bold-text, .text-proxima-bold{
  font-weight: bold;
 font-family: "Poppins", sans-serif;
}
.semibold-text{
 font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.font-20{
  font-size: 20px !important;
}
.uppercase-text{
  text-transform: uppercase;
}
h2 {
  font-size: 38px;
  line-height: 50px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}
/* .fixed-top {
  background-image:linear-gradient(to right, #3a94fa 0%, #3288fc 51%, #3c99fa 100%) !important
} */
.orange-btn.btn-default {
  font-size: 19px;
  border-radius: 32px;
  background-image:linear-gradient(to right, #0575E6 0%, #114dc1 51%, #0575E6 100%);
  text-transform: none;
  padding: 7px 23px;
  border: 3px solid #fff;
  /* background-color:#dd7202 !important ; */
}
.btn-default.orange-btn:not([disabled]):not(.disabled):active,
 .btn-default.orange-btn:not([disabled]):not(.disabled).active,
  .show>.btn-default.orange-btn.dropdown-toggle,
  .btn-default.orange-btn:hover{
    background-color: #2a6aff!important;
    color: #000;
    border-color: #2a6aff;
    background-image: none;
  }
/* =============main style============ */
section {
  padding: 80px 0 50px;
  position: relative;
}
/* section:not(:last-child):after {
  content: "";
  position: absolute;
  background-image: url("assets/images/shadow.png");
  bottom: -65px;
  background-size: cover;
  left: 0;
  width: 100%;
  height: 65px;
  background-repeat: no-repeat;
  background-size: 100% !important;
  background-position: bottom;
} */
/*section:not(:last-child):after {
  content: "";
  position: absolute;
  background-image: url("assets/images/shadow.png");
  bottom: -65px;
  background-size: cover;
  left: 0;
  width: 100%;
  height: 65px;
  background-repeat: repeat;
  background-size: 1280px !important;
  background-position: bottom;
}*/
.banner {
  background: url("assets/images/basino-banner-bg.png") #ffffff center center no-repeat;
  position: relative;
  background-size:100% 100%;
  /* margin-top: 90px; */
  padding: 100px 0 0;
  /* height: calc(100vh - 90px);  */
  min-height: 767px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
.banner .row{
  min-height: calc(100vh - 90px);
  padding:50px 0px 0;
}
.float {
  animation-name: float;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
@keyframes float {
	50%{
    transform: translateY(30px);
  }
}
.list li {
  position: relative;
  color: #000000;
  padding-left: 45px;
  padding-bottom: 15px;
  line-height: 32px;
}
.list li:last-child{
  padding-bottom: 0px;
}
.list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: -4px;
  height: 32px;
  width: 32px;
  background: url(assets/images/list-bg.png);
  background-size: contain;
}
.main-content {
  /*padding: 20px 0;*/
  font-size: 20px;
  color: #000000;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
  overflow: hidden;
}
.main-content p{
  line-height: 32px;
  text-align: justify;
  color: #000000;
  font-size: 16px;
}

.main-content li{
  line-height:25px;
  text-align:left;
  color: #000000;
  font-size: 16px;
}
.jss2{
  transition: none !important;
}
.language-button {
  padding: 6px 17px;
  font-size: 14px;
  border-radius: 33px;
  background: transparent !important;
  border: 1px solid #1d4fee;
  margin: 0 7px;
}
.language-button:hover,
.dropdown.show .language-button,
.language-button.active {
  background-color: #1d4fee !important;
  color: #fff !important;
}
.btn-default.language-button:not([disabled]):not(.disabled):active,
.btn-default.language-button:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle.language-button {
  background-color: #1d4fee !important;
}
.language-button a {
  padding: 0;
  font-size: 15px;
  line-height: 1;
}
.language-button:hover a,
.dropdown.show .language-button a {
  color: #000 !important;
}
.navbar .dropdown-menu {
  position: absolute !important;
  margin-top: 0;
  left: auto !important;
  right: 0;
  transform: none !important;
  top: 41px !important;
  background: #1f1e1e;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}
.dropdown-item {
  color: #fff;
}
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #2a6aff;
  color: #000;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #2a6aff;
}
.dropdown-item {
  padding: 6px 16px;
}
.number-span{
  display: inline-block;
  margin-left: -16px;
  margin-right: 6px;
}
.roadmap-content{
  /*max-width: 510px;*/
  margin: 270px auto 180px auto;
  position: relative;
}
.roadmap-content ul li{
  position: relative;
  font-family: "Poppins", sans-serif !important;
}
.roadmap-content ul li:last-child{
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.roadmap-content ul{
  margin-bottom: 0px;
}
.roadmap-content ul li {
  position: relative;
  padding-left: 12px;
  padding-bottom: 2px;
  font-size: 12px;
  line-height: 20px;
  color:#1E1E1E;
}
.roadmap-content ul li:before {
  content: "";
  width:4px;
  height: 4px;
  background: #1E1E1E;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top:7px;

}
.roadmap-heading-1,.roadmap-heading-2,.roadmap-heading-3,.roadmap-heading-4,.roadmap-heading-5 {
  color: #ffffff !important;
  font-size: 12px !important;
  position: absolute;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
}
.roadmap-heading-1{
  top: 62px;
  left: 86px;
}
.roadmap-heading-2{
  top: 62px;
  left: 302px;
}
.roadmap-heading-3{
  top: 62px;
  left: 518px;
}
.roadmap-heading-4{
  top: 62px;
  left:737px;
}
.roadmap-heading-5{
  top: 62px;
  left: 952px;
}
#roadmap{
  padding-bottom: 100px;
}
.roadmap-1{
  position: absolute;
  top: -230px;
  left: -10px;
  min-height: 218px;
  width: 30% !important;
}
.roadmap-2 {
  position: absolute;
  top: 157px;
  left: 171px;
  min-height: 151px;
  width: 29% !important;
}
.roadmap-3 {
  position: absolute;
  top: -230px;
  left: 349px;
  min-height: 218px;
  width: 30% !important;
}
.roadmap-4 {
  position: absolute;
  top: 157px;
  right: 172px;
  min-height: 151px;
  width: 30% !important;
}
.roadmap-5 {
  position: absolute;
  top: -229px;
  right: 68px;
  min-height: 218px;
  width: 30% !important;
}
#roadmap{
  background: #151515;
}
.roadmap-box {
  background:#ffffff;
  padding: 21px;
  border-radius: 20px;
  width: 25%;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #00000033;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;

}
.roadmap-box p{
  line-height: 16px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
}

.roadmap-1:after, .roadmap-3:after, .roadmap-5:after{
  bottom: -12px;
  top: auto;
}
.roadmap-1:after{
  margin-left: -121px;
}
.roadmap-2:after{
  margin-left: -12px;
}
.roadmap-3:after{
  margin-left: -42px;
}
.roadmap-4:after{
  margin-left: -6px;
}
.roadmap-5:after{
  margin-left: 65px;
}
.tiers-list p {
  color: #b9540e;
  margin-left: 25px;
  position: relative;
  line-height: 1;
}
.tiers-list p:not(:first-child):before {
  content: "";
  position: absolute;
  width: 1px;
  height: 88%;
  left: -15px;
  top: 1px;
  background-color: #b9540e;
}
.user-box label {
  color: #b9540e;
  margin-right: 10px;
  margin-bottom: 0;
}
.modal-title {
  color: #000000;
  font-weight: 600;
  font-size: 1.25rem;
}
.wallet_modal .modal_content {
  display: flex;
  flex-wrap: wrap;
}
.wallet_modal .modal_content > div {
  display: flex;
  flex-wrap: wrap;
  flex: 47%;
  flex-direction: column;
  text-align: center;
  margin-bottom: 25px;
}
.wallet_modal .modal_content img {
  display: block;
  margin: 0 auto 14px;
}
.wallet_modal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}
.wallet_modal .modal_content > div {
  background-color:rgb(204 219 255);
  padding: 10px;
  border-radius: 11px;
  position: relative;
}
.wallet_modal .modal_content > div.green_background {
  background-color: rgb(80 205 137 / 38%);
}
.wallet_modal .modal_content > div:nth-child(even) {
  margin-left: 10px;
}
.wallet_modal .modal_content > div:nth-child(odd) {
  margin-right: 10px;
}
.wallet_modal .modal_content > div img {
  width: 20%;
}
.wallet_modal .modal_content p {
  color: #000000;
  font-size: 15px;
}
.wallet_modal .modal-backdrop.show {
  opacity: 0.7;
}
.wallet_modal .close {
  color: #000000;
  text-shadow: none;
}

.wallet_modal .modal-header{
  border-bottom:1px solid #2876FB;
}
.wallet_modal .modal-dialog .modal-content {
  border-radius: 20px;
  box-shadow:0px 0px 6px 2px #2876FB;
}
.wallet_modal .card_footer p {
  color:#000000;
}
.wallet_modal .modal-body .modal_content {
  padding: 20px 20px 0 20px;
}
.wallet_modal .modal_content > div:hover {
  opacity: 0.65;
  cursor: pointer;
}
.wallet_modal .modal_content > div.green_background:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 4px;
  font-weight: 900;
  color: #fff;
  right: 7px;
  width: 20px;
  background: #31ef88;
  border-radius: 33px;
  height: 20px;
  font-size: 12px;
  line-height: 21px;
}
.MuiDrawer-docked {
  position: fixed;
  left: 0;
  z-index: 99999;
}
.pin_icon i {
  color: #ffffff;
  font-size: 17px;
}
.pin_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(30deg);
  cursor: pointer;
  z-index: 100;
}
.main.pin {
  margin-left: 365px;
  width: calc(100% - 365px);
  margin-right: 6px;
}
.makeStyles-appBar-2 {
  transition: none !important;
}

/* ==================card start============== */
.expired .card{
  position: relative;
}
.expired .card:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(83,74,105,0.6);
  z-index: 9;
}
.expired_box {
  height: 36px;
  line-height: 24px;
  padding: 8px 0;
}
/* .live .card {
  transform: scale(1.08);
} */
.slick-list {
  overflow-y: visible !important;
  padding: 20px 0 !important;
}
/* .live {
  margin: 0 15px;
} */
.button-next {
  position: absolute;
  right: 20px;
  top: 27px;
  border: 0;
  outline: 0;
  padding: 0;
  width: 36px;
  height: 36px;
  z-index: 10;
  background-color: transparent;
}
.button-prev {
  position: absolute;
  left: 15px;
  top: 27px;
  border: 0;
  outline: 0;
  padding: 0;
  width: 36px;
  height: 36px;
  z-index: 10;
  background-color: transparent;
}
.button-prev:focus,.button-next:focus{
  outline: none !important;
}
.button-prev:before {
  content: "\f359";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-size: 36px;
  line-height: 1;
  color: #2876FB;
}
.button-prev:before, .button-next:before {
  color: #f7607a;
  display: none;
}
.button-prev.swiper-button-disabled:before, .button-next.swiper-button-disabled:before {
  color: #C4C4C4 !important;
}
.button-next:before {
  content:"\f35a";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  color: #2876FB;
  font-size: 36px;
  line-height: 1;
}

.rounded_logo img {
  width: 60px;
}
.time_text span {
  font-size: 13px;
  font-weight: normal;
}
.cards_box > div:nth-child(1),
.cards_box > div:nth-child(2) {
  opacity: 0.5;
  cursor: not-allowed;
}
.card {
  min-height:274px;
  /* min-width:290px; */
  border-radius: 20px;
  /* height: 100%; */
}
.price_betting_card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .card.future_price_betting_card {
  height: 300px;
} */
.card.future_price_betting_card .card_middle_content {
  min-height: 128px;
}
.card.future_price_betting_card p {
  color:#2876FB;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  text-align: center;
}
.card.future_price_betting_card p:first-child {
  padding-top: 35px;
}
.small-btn {
  background: #ffffff;
  width: 35px;
  height: 35px;
  margin-left:15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2876FB;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000040;
  font-size: 17px;
}
.small-btn:hover {
  background-color: #2876FB;
  color: #ffffff;
  cursor: pointer;
}

.time_text {
  color:#2876FB;
  background: #fff;
  padding: 5px 20px 5px 9px;
  border-radius:10px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0px 0px 10px 0px #00000040;
  font-family: "Poppins", sans-serif;;
  /* min-width: 145px; */
  white-space: nowrap;
}
/* .remaining_time {
  width: 60px;
} */
.time_text i {
  color:#2876FB;
  font-size:20px;
}
.arrow_list i {
  background-color: #fff;
  color: #88acd4;
  font-size: 17px;
  padding: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 6px;
}
.price_betting_cards, .inner-wrap {
  /* background-color: #f9faff; */
  background: url(assets/images/Betting_Bg.jpg);
  padding: 40px 0px 0;
  background-size: cover;
  position: relative;
}
/* .inner-wrap:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: '';
  background:#CCDBFC;
  z-index: 111;
  height: 100vh;
} */
.main,.price_betting_cards, .inner-wrap{
  min-height: 100vh;
}
.price_betting_cards:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(204, 219, 252, 0.6);
} 
.cards_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  padding: 6px;
  align-items: center;
}
.cards_box .col {
  padding: 0;
}
.box_3{
  z-index: 9;
}
.price_betting_cards .card {
  padding: 0px;
  font-family: "Poppins", sans-serif;;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}
.price_betting_cards .market_up.card {
  /* background-image: linear-gradient(to right, #6bd075, #55aa62); */
  border: 2px solid #6F7176;
}
.price_betting_cards .live .market_up.card {
  box-shadow: 0px 0px 10px 0px #00000040;
}
.price_betting_cards .live .market_down.card {
  box-shadow: 0px 0px 10px 0px #00000040;

}
.footer-bottom p {
  font-size:16px;
  white-space: nowrap;
}
.live_box.next_box {
  border: 1px solid #2876FB;
  /* background:linear-gradient(to right, #2a6aff 0%, #2a6aff 100%); */
  color:#2876FB;
}
.live_box i {
  color: #F75873;

}
.price_betting_cards .market_down.card {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #00000040;
}
.future_price_betting_card .gray-bg{
  border-radius: 20px;
}
.next_price_betting_card.card {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #00000040;
}
.future_price_betting_card.card {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #00000040;
  min-height: 284px;
}
.footer-bottom {
  padding: 17px 12px;
  text-align: center;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-top: 1px solid #f1f1f1; */
}
.card_footer .down span,.card_footer .down p,.card_footer  p.down{
  color: #F75873; 
  font-size: 16px;
}
.card_footer .up span,.card_footer .up p,.card_footer  p.up{
  color: #1A9845;
  font-size: 16px;
}
.card_footer .down >span:first-child, .card_footer .up >span:first-child {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size:16x;
}
.footer-bottom span.value {
  font-size:16px;
  margin-right: 13px;
  line-height: 24px;
  color:#F75873;
  font-weight: 600;

}
.card-header p {
  color:#2876FB;
}
.down-price {
  border: 1px solid #F75873;
  padding: 4px 14px 4px 14px;
  border-radius: 20px;
}
.pool_price {
  border: 1px solid #2876FB;
  padding: 4px 14px 4px 14px;
  border-radius: 20px;
}
.pool_price p {
  font-size:16px;
  line-height: 24px;
  color: #2876FB;
  font-family: "Poppins";
}
.card .card-body .pool_price h3 {
  font-family: "Poppins", sans-serif;
  color: #2876FB;
  font-size: 16px;
  font-weight: 600;
}
.market_up.card {
  background: #ffffff;
}
.market_up.card .footer-bottom {
  border-top:2px solid #6F7176;
}
.market_down.card {
  background: #fff;
}
.live_box, .later_box {
  color: #2876FB;
  border: 1px solid #2876FB;
  background:#FFFFFF;
  font-size:16px;
  font-weight: 400;
  line-height: 24px;
  padding: 4px 10px;
  height: 36px;
  text-align: center;
  border-radius:20px;
  box-shadow: 0px 0px 10px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
}
.market_result i {
  font-size: 40px;
}
.market_down .market_result i {
  transform: rotate(180deg);
}
.progressbar-progress {
  height: 0px !important;
  background-color: #2876FB !important;
}
.next_price_betting_card .progressbar-container {
  background-color:#2876FB;
}
.market_up .progressbar-container {
  background: #2876FB;
}
.market_down .progressbar-container {
  background: #2876FB;
}
.white_bg_card {
  background-color: #fff;
}
.card_middle_content {
  padding: 15px 10px;
}
.card_footer {
  padding:20px 20px 0;
  min-height: 160px;
}
.card_footer p {
  color:#6F7176;
 font-family: "Poppins", sans-serif;
}
.market_up .card_footer h4, .market_up .footer-bottom span.value, 
.market_up .footer-bottom p {
  color:#1A9845;
}
.market_down .card_footer h4 {
  color:#1A9845;
}
.card .card-body .last_price_box h4 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
}
.rate_box {
  background: #7fc7c4;
  align-items: center;
  max-height: 36px;
  border-radius: 20px;
}
.rate_box > div {
  height: 36px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 16px 4px 8px;
  border-radius: 20px;
  white-space: nowrap;
}
.market_up .rate_box > div {
  background-color: #27b05a;
}
.market_up .rate_box {
  background-color: #27b05a;
}
.rate_box i {
  font-size: 17px;
  margin-left: 7px;
}
.market_down .rate_box {
  background-color: transparent;
  border: 1px solid #F75873;
  color: #f75873;
}
.market_down .rate_box i {
  transform: rotate(180deg);
}
.last_price_box p,
.locked_price_box p {
  font-size:16px;
  line-height: 24px;
  color: #1E1E1E;
}
.card .card-body .locked_price_box h5 {
  color:#1E1E1E;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;;
}
.card .card-body .locked_price_box h6 {
  color: #ff3f62;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
}
.card.market_down .card-body .locked_price_box h6 {
  color: rgb(20 165 158);
}
.card.future_card .card-body {
  align-items: center;
  justify-content: center;
  display: flex;
}
.payout_text {
  white-space: nowrap;
}
.next_price_betting_card {
  background-color: #afafaf;
}
.gray-bg .card-header {
  background-image: linear-gradient(#575549, #111111) !important;
  border-bottom: 0 !important;
  height: 55px;
  border-radius: 20px 20px 0 0;
  padding: 2px 10px !important;
  display: flex;
  align-items: center;
}
.card_btn {
  position: relative;
  color: #1A9845;
  border: 1px solid #1A9845;
  box-shadow: 0px 0px 10px 0px #00000040;
  background-color: transparent;
  width: 147px;
  display: block;
  font-weight: 700;
  padding: 8px 10px;
  font-size: 13px;
  line-height: 20px;
  border-radius: 20px;
  font-family: "Poppins", sans-serif !important;
}
.card_btn:hover
{
  color: #fff;
  background-image: linear-gradient(#34d368, #27b05a);
}
.card_btn:focus{
  outline: none;
}
.slick-slide {
  margin: 0 5px;
}
.slick-list {
  margin: 0 -5px;
}
.later_box {
  font-size: 16px;
  color: #2876FB;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-header {
  border-bottom:1px solid #6F7176 !important;
}
.card-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81%;
}
.timing-minutes {
  background: linear-gradient(172.72deg, #43A2F8 1.68%, #2571FC 100.58%);
  width: 40px;
  height: 37px;
  border-radius: 16px 0px 0px 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 4px 5px 3px 9px;
}
.timing-seconds {
  background: linear-gradient(172.72deg, #43A2F8 1.68%, #2571FC 100.58%);
  width: 40px;
  height: 37px;
  border-radius:0px 16px 16px 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 4px 13px 3px 9px;
}
/* .timing {
  color: #ffffff;
  font-size: 32px;
  color: #fff !important;
  background: #2876FB;
  padding: 4px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
} */
.card_btn.down {
  color: #F75873;
  white-space:nowrap;
  /* background-image:linear-gradient(#2a6aff, #161eb3); */
  border: 1px solid #F75873;
}
.card_btn.down:hover {
  color: #fff;
  background-image:linear-gradient(#F75873, #c12e46);
}
.card_btn i {
  font-size: 17px;
}
.future_price_betting_card {
  background-color: #afafaf;
}
.empty_card_btn {
  color: #000 !important;
  background: #fff !important;
  box-shadow: 0 0 3px 3px #b7b7b72e;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
}
.white_logo_bg{
  background: linear-gradient(172.72deg, #43A2F8 1.68%, #2571FC 100.58%);
  box-shadow: 0px 0px 10px 0px #00000040;
  color: #fff;
  border-radius: 16px;
  padding: 10px 20px 10px 33px;
  position: relative;
  width: 230px;
  margin-left: 30px;
}
.usdLogo {
  background-image: url(assets/images/bnbtousd.png);
  width: 100px;
  background-repeat: no-repeat;
  height: 80px;
  position: absolute;
  left: -52px;
  z-index: 100;
}
.white_logo_bg h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 0;
  margin: 0 5px;
}
.white_logo_bg span {
  color: #fff;
  font-size: 13px;
  margin-left: 8px;
  font-weight: 300;
}
.gray-bg {
  background: #292929;
  height: 100%;
  border-radius: 20px 20px 0 0;
}
.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
}
.pool-price {
  color:#1E1E1E;
  font-size: 16px;
  font-weight: 600 !important;
}
.market_value_arrow i {
  font-size:18px;
  margin-right: 8px;
}
.market_up .market_value_arrow i {
  color: #1A9845;
  transform: rotate(180deg);
}
.market_down .market_value_arrow i, .market_down .footer-bottom span.value, 
.market_down .footer-bottom p  {
  color:#F75873;
}
.loader-box .sweet-loading span{
  position: relative;
  margin-bottom: 8px;
}
.calculating_card  .gray-bg,.react-card-back .card .gray-bg {
  border-radius: 20px;
}
.loader-box {
  background: #191818;
  padding: 15px 20px;
  border-radius: 16px;
  margin: 1px 10px;
  text-align: center;
  z-index: 9999;
  position: relative;
  z-index: 9;
}
.calculating_card,.react-card-back .card {
  min-height: 300px;
}
.card.calculating_card .card_footer{
  position: relative;
  height: 82%;
}
.react-card-back .card .form-control {
  background-color: #504f4f;
  border: 1px solid #504f4f;
  color: #fff;
  text-align: right;
  height: 30px;
}
.connect-btn.btn-secondary {
  background-color: #37D369 !important;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin: 13px 0 5px;
}
.next_price_betting_card .btn-grey {
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin: 13px 0 5px;
}
.next_price_betting_card .disabled{
  opacity: 0.4;
}
.react-card-back .card .form-control:focus{
  box-shadow: none;
}
.card.calculating_card .card_footer:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 100%;
  height: 100%;
  background-color: rgba(83,74,105,0.6);
  z-index: 9;
  border-radius: 0 0 20px 20px;
}
.card.calculating_card .show.tooltip{
  top: 26px !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 150px;
}
.card-header .btn {
  padding: 7px 15px;
  font-size: 14px;
  border-radius: 20px;
  color: #2876fb;
}
.card-header .btn.UP {
  background-color: #37D369 !important;
}
.card-header .btn.DOWN {
  background-color: #ffffff !important;
}
/* =========Range Slider starts============ */
.card_footer .rangeslider-horizontal {
  height: 6px;
}
.card.up .card_footer .rangeslider-horizontal .rangeslider__fill {
  background-color:#37D369;
}
.card.down .card_footer .rangeslider-horizontal .rangeslider__fill {
  background-color:#fa2f7c;
}
.card_footer .rangeslider-horizontal .rangeslider__handle {
  width: 20px;
  height: 20px;
  border-radius: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
}
.card_footer .bottom-text {
  line-height: 1.2;
  color: #9c9b9b;
}
.card_footer .rangeslider {
  margin:3px 0 15px;
}
.percentage-value-list div{
  color:#37d369 ;
}
.percentage-value-list div {
  color: #37d369;
  background: rgb(55 211 105 / 20%);
  padding: 1px 7px;
  border-radius: 33px;
  font-size: 13px;
  cursor: pointer;
}
.card_footer .rangeslider-horizontal .rangeslider__handle:after {
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0;
}
.card.down .card_footer .rangeslider {
  background: rgb(250 47 124 / 28%);
}
.card.up .card_footer .rangeslider-horizontal .rangeslider__handle:after{
  background-color: #37d369;
}
.card.down .card_footer .rangeslider-horizontal .rangeslider__handle:after{
  background-color:#f75873;
}
.card.up .card_footer .rangeslider-horizontal {
  height: 6px;
  background: rgb(87 174 100 / 44%);
}
.card.down .percentage-value-list div {
  color: #f75873;
  background: rgb(250 47 124 / 20%);
}
.swiper-wrapper{
  padding:0 20px;
}
@media(max-width:767px){
  .react-card-back .card{
    height: auto;
  }
}
/* =========Range Slider End============ */
/* =========History style start-=========*/
.history_modal .modal-dialog{
  top: 78px;
}
.history_modal .modal-content {
  border-radius: 0;
  background:#c8d9ff;
}
.custom_tab .MuiTabs-flexContainer {
  border: 1px solid #6f6f6f;
  border-radius: 33px;
}
.custom_tab .MuiButtonBase-root {
  flex: 1 1;
  min-height: 32px;
  color:#2876FB;
  font-weight: 600;
}
.custom_tab .MuiButtonBase-root:focus{
  outline: 0;
}
.custom_tab .MuiButtonBase-root.Mui-selected {
  color: #ffffff;
  background: #2a6aff;
  border-radius: 33px;
}
.MuiTabs-indicator{
  display: none;
}
.MuiTabPanel-root{
  padding: 0 !important;
}
.history_div .bg-color {
  background: #ffffff;
  padding: 0 15px;
}
.history_div .MuiFormControlLabel-root{
  margin-left: 0;
}
.history_div .MuiFormLabel-root{
  color: #000000 !important;
  font-size: 13px;
}
.history_div .MuiTypography-body1 {
  font-size: 14px;
  margin-left: 6px;
  color: #2a6aff;
}
.history_div .MuiRadio-root.Mui-checked {
  color: #2a6aff;
}
.history_div .MuiTabs-root{
  min-height: 42px;
}
.history_div .round_result_calculated{
  background:#7ea0ee;
}
.label{
  color: #000000;
  font-size: 13px;
}
.round_result_calculated .lead {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000000;
}
.lead {
  color: #000000;
  font-weight: 600;
}
.history_div .card {
  background: #ffffff;
  padding: 16px;
  min-height: auto;
  color: #000000;
}
.btn-up{
  background: #27b05a !important;
  padding: 4px 14px;
  font-size: 15px;
  margin-right: 0;
}
.btn-market-down{
  background: #f44336 !important;
  padding: 4px 14px;
  font-size: 15px;
  margin-right: 0;
}
.history-text {
  color: #000 !important;
}
.btn-down{
  background: #2a6aff !important;
  padding: 4px 14px;
  font-size: 15px;
}
.history_modal .modal .modal-dialog.modal-right {
  right: 0 !important;
  margin: 0;
  left: auto;
  margin-left: auto;
}
.connect-btn.disabled{
  padding: 8px;
  background: #464646 !important;
}
.close-btn{
  cursor: pointer;
}
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ffffff !important;
  box-shadow: 4px 2px 20px 0px #00000040 !important;
}
/* ============tier crads style============ */
.tier-cards .col{
  flex: 1 1 33.33%;
  margin:20px 0;
}
.tier-cards .card{
  background-image: url("assets/images/Tiers_bg.png");
  padding-bottom: 30px;
  background-size:99% 100%;
  margin-bottom:8px;
  background-repeat: no-repeat;
  box-shadow:none !important;
  min-height:520px;
}
.tier-cards .card h4.card-title {
  font-size: 16px;
  margin-bottom: 8px;
  color:#e0e9ff;
}
.tier-cards .card .card-text {
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 600;
  text-align: center !important;
  line-height: 24px;
  margin-top: 17px;
}
.tier-cards .card .orange-btn.btn-default{
  font-size: 14px;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  background-image: linear-gradient(10deg, #5d88eb 0%, #1e58f2 100%);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
  padding: 9px 30px;
  border: 0;
  text-transform: uppercase;
}
.tier-cards .card .orange-btn.btn-default:hover {
border:0 !important;
box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.3);
}
.footer i, .footer p {
  color: #ffffff;
}
.swiper-slide{
  margin: 22px 0;
}
#ecosystem img:hover{
cursor: zoom-in;
}
.eco-list-items li {
  line-height: 30px;
  margin-bottom: 8px;
  font-family: "Poppins", sans-serif !important;
}
.ril__caption, .ril__toolbar{
  background-color: transparent !important;
}
.dropdown.connect-wallet-dropdown .btn {
  padding: 6px 14px 6px 36px;
  background: #6e6e6e!important;
  color: #fff;
  border-radius: 33px;
  margin-right: 30px;
  position: unset;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}
.dropdown.connect-wallet-dropdown .btn i{
  background: #f0bc05;
    padding: 12px;
    position: absolute;
    left: -6px;
    color: #000;
    border-radius: 50%;
    top: 3px;
    font-size: 16px;
}
.dropdown.connect-wallet-dropdown  .dropdown-menu {
  background-color: #29282d;
}
.dropdown.connect-wallet-dropdown  .dropdown-menu .dropdown-item {
  padding: 4px 16px;
  font-size: 14px;
}
.dropdown.connect-wallet-dropdown .dropdown-divider {
  border-top: 1px solid #3c3b3b;
}
.MuiListItem-root.Mui-selected,
 .MuiListItem-root.Mui-selected:hover,
 .MuiListItem-button:hover {
  background-color: #ffffff !important;
}
.dropdown.connect-wallet-dropdown .dropdown-item:focus,
.dropdown.connect-wallet-dropdown  .dropdown-item:hover {
  background-color:#3e3e3e;
  color: #fff;
  border: 0;
    outline: 0;
}
.dropdown.connect-wallet-dropdown .btn-primary:not([disabled]):not(.disabled):active, 
.dropdown.connect-wallet-dropdown .btn-primary:not([disabled]):not(.disabled).active,
.dropdown.connect-wallet-dropdown .show>.btn-primary.dropdown-toggle{
  background-color: #605f5f !important;
 }
.dropdown.connect-wallet-dropdown > .btn:hover > .dropdown-menu{
  display: block !important;
}
.dropdown-nft .MuiListItemText-root{
  display: flex;
  align-items: center;
}
.dropdown:hover>.dropdown-menu.dropdown-nft-menu {
	display: block;
	/* background-image: linear-gradient(270deg,#E9AC1D 0% ,#F76B1C 100%); */
	/* border-radius: 21px; */
	width: 100%;
	overflow: hidden;
	padding: 0;
	background:#ffffff;
	border: none;
	margin-top: -1px;
	border-radius: 0px;
}
.sidenav-list .MuiListItem-root .dropdown-item:focus, .sidenav-list .MuiListItem-root .dropdown-item:hover{
	background:transparent;	
}
.sidenav-list .MuiListItem-root .dropdown-nft-menu .dropdown-item{
	color:#1d4fee !important;
}
.sidenav-list .MuiListItem-root .dropdown-nft-menu .dropdown-item:hover, .sidenav-list .MuiListItem-root .dropdown-nft-menu .dropdown-item:focus{
	color:#1d4fee !important;
}
.dropdown-nft-menu .dropdown-item {
	padding: 5px  0px  8px  74px;
	font-size: 16px;
	outline: none !important;
}
/*========== new landing page css start ==========*/
.text-dmsans{
  font-family: 'Poppins', sans-serif;
}
/* .text-yellow{
  color: #2876FB;
} */
.text-orange{
  color: #f5771c;
}
.fs-20 {
  font-size: 20px;
}
.banner p{
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  /* font-weight: 700; */
}
.banner .orange-btn.btn-default{
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  border: 1px solid #FFFFFF;
  background: #FFFFFF !important;
  padding: 11px 30px;
  color:#2673FB;
  line-height: 16px;

}
.seprater{
  position: relative;
}
.seprater hr{
  border-top: 1px solid rgb(255 255 255 / 10%);
  position: absolute;
  margin: 0px;
  left: -100%;
  width: 300%;
}
.main-content .text-center{
  text-align: center;
}
.image-shadow{
  border-radius: 20px;
  box-shadow: 0px 0px 12.42px 14.58px rgba(0, 0, 0, 0.1);
}
.footer h3{
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 15px;
}
.footer ul{
  padding: 0px;
  margin: 0px;
}
.footer li{
  list-style-type: none;
  display: inline-block;
}
.footer li a{
  padding: 0px 10px;
}
#contactus{
  /* background: #131212; */
  background: #2876FB;
  padding: 50px 0 10px;
}
.footer .btn {
  font-size: 15px;
  color: #000 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  background-image: linear-gradient(10deg,#e9ac1d 0% ,#f76b1c 100%);
  border: none !important;
  text-transform: capitalize;
  border-radius: 100px;
}
.footer .form-control{
  border: 1px solid #635f5f;
  border-radius: 100px;
  color: #504a4a;
  height: calc(1.5em + .75rem + 7px);
  padding-left: 20px;
  font-size: 15px;
  background: transparent;
}
.tier-cards .card-body{
  padding-bottom: 5px;
}
.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), .md-form input[type="phone"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]){
	border-bottom: 1px solid #ffffff;
	box-shadow: 0 1px 0 0 #ffffff;
}
.md-form input:not([type]):focus:not([readonly])+label, .md-form input[type="text"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="password"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="email"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="url"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="time"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="date"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="tel"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="number"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="search"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="phone"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="search-md"]:focus:not([readonly])+label, .md-form textarea.md-textarea:focus:not([readonly])+label {
    color: #ffffff;
}
.footer-form .md-form .prefix.active {
	color: #ffffff;
}
.md-form .form-control {
    margin-left: 1.5rem;
	padding-left:0px;
    font-size: 18px;
    color: #fff;
	font-family: 'Poppins', sans-serif;
}
.footer-form .btn {
	font-size: 18px;
	color: #000;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	background-image: linear-gradient( 
	10deg,#e9ac1d 0% ,#f76b1c 100%);
	box-shadow: 0px 0px 0px 5px rgb(246 220 52 / 10%);
	padding: 10px 40px;
}
.footer-form form {
	background: #151515;
    padding: 35px;
	border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0 0 0 / 20%);
}
.md-form label{
	font-size: 18px;
	transform: translateY(8px);
}
.footer-form .md-form .prefix {
    top: 10px;
    font-size: 21px;
}
.tokenmics-table{
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0px 4px 10px rgb(0 0 0 / 30%);
}
.tokenmics-table td{
	color:white;
	font-size:18px;
	border:none;
	padding: 10px  15px !important;
}
.tokenmics-table tr td:first-child{
	background: #2f2e30;
}
.tokenmics-table tr td:last-child{
	background: #2876FB;
    color: black;
    text-align: center;
    border: none;
	font-weight:700;
}
.tokenmics-table tr:nth-child(even) td:first-child{
	background: #29282d;
}
.tokenmics-table tr:nth-child(even) td{
	background: #e5cb22;
}
.footer-form .btn:hover, .tier-cards .card .orange-btn.btn-default:hover, .banner .orange-btn.btn-default:hover{
  background-image: linear-gradient(to right, #0058b0 0%, #295dc5 51%, #2a87fa 100%) !important;
  border: 3px solid #ffffff !important;
  color: #fff !important;
}
.seed-bg{background:#4a75c7 !important;}
.private-bg{background:#f0813d !important;}
.public-bg{background:#a1a1a1 !important;}
.liquidity-bg{background:#fbc30f !important;}
.team-bg{background:#5499d8 !important;}
.advisors-bg{background:#71b147 !important;}
.marketing-bg{background:#25447c !important;}
.reserve-bg{background:#9e4204 !important;}
.listing-bg{background:#666666 !important;}

.eco-listbox{
	justify-content:center;
	align-items:center;
}
.eco-leftside{
	flex: 0 0 34.333333%;
    max-width: 34.333333%;
}
.eco-center{
	flex: 0 0 35.333333%;
    max-width: 35.333333%;
	padding-left:25px;
	padding-right:25px;
}
.eco-rightside{
	max-width: 30.333333%;
}

.eco-list-item{
	display: flex;
	align-items: flex-start;
	/* margin-bottom:25px; */
	position:relative;
}
.eco-listbox .col-lg-4 .eco-list-item:not(:last-child){
	margin-bottom:35px;
}
.eco-leftside .eco-list-item.alter{
	margin-right: 25px;
	margin-left: -25px;
}
.eco-rightside .eco-list-item.alter{
	margin-right: -25px;
	margin-left: 25px;
}
.eco-leftside .eco-list-item:after{
	content: "";
	position: absolute;
	right: -40px;
	top: 0px;
	width: 12px;
	height: 100%;
	background: url(assets/images/economics-list-ic.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center right;
	transform: rotate(180deg);
}
.eco-rightside .eco-list-item:after{
	content: "";
	position: absolute;
	left: -40px	;
	top: 0px;
	width: 12px;
	height: 100%;
	background: url(assets/images/economics-list-ic.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center left;
}
.eco-list-item span{
	min-height: 70px;
	min-width: 70px;
	/* background: url(assets/images/economics-list-bg.png); */
	background: #fff;
	background-size: contain;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:20px;
	border-radius: 100px;
    border: 2px solid #1a477d;
}
.eco-rightside .eco-list-item span{
	margin-right: 0px;
	margin-left: 20px;
	order:1;
	margin-left: auto;
}
.eco-list-item img{
	max-width:52px;
	max-height:52px;
}
.eco-list-item p{
	margin-bottom: 0px;
	align-self: center;
	font-size: 15px;
	line-height: 21px;
	text-align: left;
}
.eco-leftside .eco-list-item p{
	text-align:right;	
}
.eco-betbnb-logo {
	background: #101010;
    border-radius: 100px;
    height: 60px;
    width: 60px;
    box-shadow: 0 0 0 0 rgb(255 255 255);
    transform: scale(1);
    animation: pulse 2s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    border: 3px solid #2876FB;
    margin-left: 20px;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(246, 220, 50, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(246, 220, 50, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(246, 220, 50, 0);
	}
}
.tokenmics-row{
	position:relative;
	text-align: center;
}
.tokenmics-container{
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}
.tokenmics-image{
	display: inline-block;
  padding:10px;
}
.tokenmics-labels{
	position:absolute;
	height:100%;
	width:100%;
}
.tokenmics-labels p{
	position:absolute;
	margin-bottom:0px;
  font-weight: 600;
}
.tokenmics-labels p span{
	margin-left:10px;
	background: -webkit-linear-gradient(#1d4fee , #1d4fee);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight:700;
}
.tokenmics-labels .tkm-community{
  left: 45%;
  top: -2%;
}
.tokenmics-labels .tkm-team{
	right: 2%;
	top: 2%;
}
.tokenmics-labels .tkm-public{
	right: -25%;
	top: 26%;
}
.tokenmics-labels .tkm-liquidity{
	right: -10%;
    bottom: 49%;
}
.tokenmics-labels .tkm-private{
	left: 43%;
    bottom: -7%;
}
.tokenmics-labels .tkm-marketing{
	left: -11%;
	bottom: 7%;
}
.tokenmics-labels .tkm-ecosystem{
	left: -15%;
    top: 42%;
}
.tokenmics-labels .tkm-listing{
  left: -20%;
  top: 17%;
}
.tokenmics-labels .tkm-staking{
  left: 4%;
  top: 2%;
}


#ecosystem,#roadmap,#tokenmics,#tiers{
  background: #ffffff;
  position: relative;
  z-index: 9;
  background-position-x: right;
  background-size: 100%;
}
#ecosystem:before,#roadmap:before,#tokenmics:before,#tiers:before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-color:#d3defc; */
  top: 0;
  z-index: -1;
}
/*========== new landing page css ends ==========*/
/*========== on chain bet NFT page start ==========*/
.page-title{
	font-size:24px;
  line-height: 30px;
	font-weight:600;	
	margin-bottom:25px;
  color:#2876FB;
}
.inner-greybox{
	background:#292929;
	padding:25px;	
	border-radius:15px;
}
.inner-greybox-bordered{
	background:transparent;
	border-radius:15px;
	padding:2px;
  height: 100%;
}
.inner-greybox-bordered .inr-greybx-cont{
	padding:31px 31px 0;	
	border-radius:20px;
  border: 2px solid #2876FB;
  background-position: 20% 100%;
  position: relative;
  height: 100%;
}
.close{
  opacity: 1;
}
.modal-content{
  background: #ffffff;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.pattern-box {
  background-color: transparent;
}
.with-bg {
  box-shadow: 0px 0px 20px 0px #00000040;
  background: #FFFFFF;
}
/* .pattern-box:after,.modal-content:after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(assets/images/dotted-bottom-left.png);
  width: 263px;
    height: 174px;
    z-index: -1;
    opacity: 0.8;
}
.pattern-box:before,.modal-content:before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(assets/images/dotted-top-right.png);
  width: 202px;
  height: 153px;
    z-index: -1;
    opacity: 0.8;
} */
.inner-wrap{
  z-index: 99;
  position: relative;
}
/* .inner-wrap:before{
  content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgb(14 14 14 / 60%);
    top: 0;
    z-index: -1;
} */
.mintbet-form label{
	color:#1E1E1E;
	font-size:18px;
  line-height: 22px;
}

.mintbet-form .form-control{
	border-radius: 10px;
	background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px #00000040;
	outline:none;
	border:none;
	color:#3c3c3c;
	font-size:16px;
  /* height: 50px; */
}
.form-control[type="text"], .form-control[type="number"], .custom-select   {
  height: 50px;
}
.mintbet-form .form-control[type="radio"] {
  box-shadow: none !important;
  }
  
  textarea {
    min-height: 70px;
  }
  
.mintbet-form .onchain-autofill-dropdown{
	position: relative;
	z-index: 2;
}	
.mintbet-form .onchain-autofill-dropdown .form-control{
	height: calc(1.9em + 0.75rem + 2px);
}	
.mintbet-form .onchain-autofill-dropdown .jZkBQk > .wrapper {
  box-shadow: 0px 4px 4px 0px #00000040;
 border-radius: 10px;
}
.mintbet-form .input-group span{
	position: absolute;
	z-index: 99999;
	margin-left: 10px;
	margin-top:13px;
  color: #1E1E1E;
} 
.mintbet-form .input-group .form-control{
	padding-left: 22px;
	border-radius: 10px !important;
} 
.mintbet-form .inputbg{
	border-radius: 10px;
  height: 50px;
	background-color: #ffffff;
	outline:none;
	box-shadow:none;
	border:none;
	color:#3c3c3c;
	font-size:16px;
	display: block;
    width: 100%;
    padding:0;
    box-shadow: 0px 0px 5px 0px #00000040;
}	
.mintbet-form .inputbg .makeStyles-textField-12 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
.mintbet-form .inputbg .MuiInputBase-input{
    padding:4px 0 4px;
	color: #fff;
}
.mintbet-form .inputbg input[type="time" i]{
	color: #fff !important;
}
.mintbet-form .inputbg .react-date-picker{
	width:100%;
  padding: 5px;
  box-shadow: 0px 0px 5px 0px #00000040;
  height: 50px;
  border-radius: 10px;
}
.picktime-div{
  padding: 5px;
  position: relative;
}
.small-text{
  line-height: 1;
}
.mintbet-form .inputbg .react-date-picker__inputGroup__input{
	color: #3c3c3c;
	outline: none;
	border: none;
}
.mintbet-form .inputbg .react-date-picker button {
    outline: none;
	border: none;
}
.mintbet-form .inputbg .react-date-picker__button:enabled:hover .react-date-picker__button__icon, .mintbet-form .inputbg .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #2876FB;
}
.mintbet-form .inputbg .react-date-picker__button svg {
    stroke: #9d9d9d;
}
.mintbet-form .inputbg .react-date-picker__calendar-button svg {
  display: none;
}
.mintbet-form .inputbg .react-date-picker__calendar-button {
  background-image:url(./assets/images/icons/calendar_month.png);
  background-repeat: no-repeat;
  background-size: 81%;
  height: 24px;
  width: 22px;
  margin-right: 4px;
  margin-top: 9px;
}
.form-txt-clr {
  color: #1E1E1E;
}
.timepicker-icon {
  background-image:url(./assets/images/icons/clock-icon.png);
  background-repeat: no-repeat;
  background-size: 81%;
  display: inline-block;
  height: 18px;
  width: 22px;
  position: absolute;
  top: 17px;
}
.mintbet-form .inputbg .react-date-picker .react-calendar__tile--active {
    background: #2a6aff;
    color: #000;
}
.mintbet-form .inputbg .react-date-picker__wrapper{
	border: none;
}
.btn-theme-color{
	font-size: 24px;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
  background: #2876FB !important;
	padding: 10px 16px;
	color:#ffffff;
	border-radius:10px;
}
.btn-theme-color:hover, .btn-theme-color:focus{
	background: #2a6aff !important;
    background-color: #2a6aff !important;
	box-shadow: 0px 0px 0px 5px rgba(246,220,52,0.1);
	color:#ffffff;
}
.onchairbet-contact-card .form-control{
	border-radius: 5px;
	background-color: #504f4f;
	box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.27);
	outline:none;
	border:none;
	color:#fff;
	font-size:16px;
}
.line-height1-4{
	line-height:1.4;
}
.line-height1-5{
	line-height:1.5;
}
.line-height1-7{
	line-height:1.7;
}
.offchairbet-tabs .nav {
    flex-direction: inherit;
	display: flex !important;
}
.offchairbet-tabs .nav-link{
    color:#2876FB;	
    font-size:20px;
    font-weight: 600;
	border-radius:10px;
}
.offchairbet-tabs .nav-link.active, .offchairbet-tabs .show>.nav-link{
  color:#ffffff;
  border-radius: 10px;
  background-color: #2876FB;
}
.offchairbet-tabs .tab-content{
	margin-top: -10px;
	margin-bottom: -35px;
}
.picktime .rc-time-picker{
    width: calc(100% - 28px);
}
.picktime .rc-time-picker-input{
	background: transparent;
    border: none;
    color:#3c3c3c;
    font-weight: 400;
    outline: none;
    box-shadow: none;
    height: 40px;
    width: 100%;
	font-weight: 400 !important;
	font-size: 16px  !important;
}
.rc-time-picker-panel-input{
	font-weight: 400 !important;
	font-size: 16px  !important;
}
li.rc-time-picker-panel-select-option-selected {
    background:#2876FB  !important;
    color: #000 !important;
    font-weight: 400 !important;
}
.rc-time-picker-panel-inner .rc-time-picker-panel-select li{
	font-weight: 400 !important;
	font-size: 15px  !important;
	padding: 5px 0 0 20px  !important;
	height: 35px  !important;
}
.rc-time-picker-panel{
	width: 220px !important;
}
.rc-time-picker-panel-inner .rc-time-picker-panel-select{
	width: 74px !important;
}
.rc-time-picker-panel-select li:hover {
    background-color: #e6e6e6 !important;
}
.rc-time-picker-panel-inner{
	border: none  !important;
	box-shadow: none  !important;
}
.picktime .rc-time-picker-clear{
top: 12px !important;
}
.picktime .rc-time-picker-clear-icon:after {
    font-size: 16px !important;
    color: #9d9d9d  !important;
	content: "\f00d"  !important;
	font-weight: 900  !important;
	font-family: "Font Awesome 5 Free"  !important;
	-webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    top: 11px;
}
.picktimeic{
	color: #9d9d9d !important;
	position: absolute;
    right: 30px;
    margin-top: 14px;
}
.picktimeic:hover, .picktime .rc-time-picker-clear-icon:hover:after{
	color: #2876FB !important;
}
.react-calendar__month-view__weekdays__weekday {
    color:#000;
}
.opencalendar .MuiAppBar-positionFixed{
	z-index: 1;
}
/*========== on chain bet NFT page ends ==========*/

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 8px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 8px;
  left: -1px;
  position: relative;
  background-color: #2876FB;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.custom-margin{
  margin-top: 54px;
}
/* .banner:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 99px;
  bottom: 0;
  opacity: 1;
  background: linear-gradient(to bottom, rgb(141 180 255) 0%, rgba(23, 186, 255, 0) 100%);
} */
.main-content:before,#roadmap:after,#tokenmics:after,#tiers:after{
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 99px;
  top: 0;
  opacity: 1;
}
#roadmap:after, #tiers:after, #tokenmics:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 99px;
  top: 0;
  opacity: 1;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background:linear-gradient(180deg, #DFE7FE 0%, rgba(223, 231, 254, 0) 100.3%);
}
/* =============== media query ============== */
@media (max-width: 1199px) {
  .cards_box {grid-template-columns: 1fr 1fr;}
  .card {min-height: 280px; min-width: auto !important;}
  .inner-greybox-bordered .inr-greybx-cont{
    min-height: 543px;
  }
}
@media (max-width: 991px) {
  .banner {background-position: top left; background-size: cover;}
  .navbar-toggler {margin-top: 12px;}
  .navbar .dropdown-menu {right: auto !important;left: 0 !important;}
  .white_logo_bg {padding: 7px 20px 7px 53px;}
  .white_logo_bg h3 {font-size: 15px;}
  .rounded_logo {width: 50px;height: 50px;}
  .price_betting_cards .d-flex{flex-wrap: wrap;}
  .price_betting_cards .box_1{order: 1;}
  .price_betting_cards .box_2 {order: 3;display: flex;justify-content: center;margin: 0 auto;}
  .price_betting_cards .box_3 {order: 2;flex: 60%;display: flex;justify-content: flex-end;}
  .eco-leftside,.eco-center,.eco-rightside {
    flex: 0 0 100%;
    max-width: 100%;
}
.betbnb-center-logo{
  width: 300px !important;
}
.eco-leftside .eco-list-item:after,
.eco-rightside .eco-list-item:after{
  display: none;
}
.eco-leftside .eco-list-item.alter {
  margin-right: 0px;
  margin-left: 0;
}
.eco-leftside .eco-list-item p {
  text-align: left;
}
.eco-rightside .eco-list-item span {
  margin-left: 0;
  order: 1;
  margin-right: 20px;
}
.eco-list-item p {
  order: 2;
  margin-left: 10px;
}
.eco-rightside .eco-list-item.alter {
  margin-right: 0;
  margin-left: 0;
}
.tokenmics-labels p{
  font-size: 18px;
}
.tokenmics-labels .tkm-reserve {
  left:-3%;
  top: 44%;
}
.tokenmics-labels .tkm-public {
  right: -17%;
  top: 32%;
}
.tokenmics-labels .tkm-listing {
  left: -17%;
  top: 18%;
  width: 100px;
}
.tokenmics-labels .tkm-ecosystem {
  left: -20%;
  top: 44%;
}
.tokenmics-labels .tkm-liquidity {
  right: -15%;
}
.tokenmics-labels .tkm-marketing {
  bottom: 16%;
}
.inner-greybox-bordered .inr-greybx-cont{
  min-height: auto;
}
}
@media (min-width: 768px)  and (max-width: 1199px){
	/* roadmap start */
	.roadmap-content img{display: none;}
	.roadmap-1,.roadmap-2,.roadmap-3,.roadmap-4,.roadmap-5{
    position: relative;
    top: auto;
    left: auto;
    width: 100%!important;
    margin-top: 148px;
    margin-bottom: 182px;
    right: auto;
  }
	.roadmap-5{margin-bottom: 0px;}
	.roadmap-5:after{display:none !important;}
	.roadmap-1{width: 100% !important;}
	.roadmap-heading-1, .roadmap-heading-2, .roadmap-heading-3, .roadmap-heading-4, .roadmap-heading-5{display: none;}
	.roadmap-content ul li{font-size: 15px;}
	.roadmap-content {margin: 0;}
	.roadmap-box:before{
    	content: "";width: 107px;height: 148px;display:block;background-image: url("assets/images/roadmap-bg-single.png");
      margin: -361px auto 0px auto;
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding-top: 30px;
    position: absolute;
    left: 0;
    right: 0;
  }
	.roadmap-box:after{content: "";width: 17px;height: 50px;display:block;background-image: url("assets/images/roadmap-bg-aero.png");margin: 0px auto 0px auto;position: absolute;top: auto;bottom: -50px;left: 0;box-shadow: none;right: 0;text-align: center;}
	.roadmap-1:before{content:"Q3 2021";}
	.roadmap-2:before{content:"Q4 2021";}
	.roadmap-3:before{content:"Q1 2022";}
	.roadmap-4:before{content:"Q3 2022";}
	.roadmap-5:before{content:"Q4 2022";}
	/* roadmap ends */

}
@media (max-width: 767px) {
  .banner p {font-size: 20px;line-height: 1.5em;}
  .dropdown.connect-wallet-dropdown .btn{margin-bottom: 25px;}
  /* ==================card start============== */
  .slick-slide > div:focus-visible{outline: none !important;}
  /* .live .card{transform: scale(1);}  */
  .live{margin: 0 15px;}
  .slick-next {display: block;position: absolute;right: 46%;top: -20%;opacity: 1;}
  .slick-prev{display: block;position: absolute;left: 41%;top: -20%;}
  .slick-prev:before, .slick-next:before{opacity: 1;}
  .scene .outer-cube {width: 90px;height: 90px;}
  .tiers-list {flex-direction: column;}
  .tiers-list p {margin-bottom: 10px !important;text-align: right;}
  .tiers-list p:not(:first-child):before {display: none;}
  .wallet_modal .modal_content > div {flex: 40% 1;}
  .wallet_modal .modal_content p {white-space: break-spaces;}
  .price_betting_cards {background-position: 18% 79%;}
  .cards_box {grid-template-columns: 1fr;}
  .price_betting_cards > div:first-child {flex-wrap: wrap;}
  .arrow_list i {margin-top: 9px;}
  .price_betting_cards .card {width: 100%;margin-bottom: 10px;margin-top:10px;}
  .pin_icon {display: none;}
  .main {width: 95%;}
  .white_logo_bg {margin-bottom: 18px !important;}
  .slick-prev {left: 40%;}
  .slick-next {right: 40%;}
  .navbar-collapse {
   background:#2472fb;
    z-index: 99999;
    margin-top: 51px;
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%; 
  }
  .navbar-collapse ul{padding: 10px !important;}
  .navbar-secondary li {margin-bottom: 20px;margin-top: 0 !important;}
  .navbar-top{margin-top: 20px !important;}
  .navbar-nav p{flex: 1 1 100%;}
  .price_betting_cards .box_3 {justify-content: flex-start;margin-bottom: 47px;flex: 100%;}
  .tiers-info {align-items: flex-start;margin-top: 15px;}
  .tiers-list p {text-align: left;}
  h2{ font-size: 22px;margin-bottom: 10px;line-height: 1.4em;}
  h3{font-size: 20px;margin-bottom: 4px;}
  .rounded_logo img {width: 25px;}
  .swiper-wrapper{padding:0px;}
  /* roadmap start */
  .roadmap-content img{display: none;}
  .roadmap-1,.roadmap-2,.roadmap-3,.roadmap-4,.roadmap-5{position: relative;top: auto;left: auto;width: 100% !important;margin-bottom: 5px;margin-top: 148px;margin-bottom: 182px;right: auto;}
  .roadmap-5{margin-bottom: 0px;}
  .roadmap-5:after{display:none !important;}
  .roadmap-1{width: 100% !important;}
  .roadmap-heading-1, .roadmap-heading-2, .roadmap-heading-3, .roadmap-heading-4, .roadmap-heading-5{display: none;}
  .roadmap-content ul li{font-size: 15px;}
  .roadmap-content {margin: 0;}
  .roadmap-box:before{	content: "";width: 107px;height: 148px;display:block;
    background-image: url("assets/images/roadmap-bg-single.png");
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding-top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    top: -134px;
  }
  .roadmap-box{
    min-height: auto;
  }
  .roadmap-box:after{content: "";width: 17px;height: 50px;display:block;background-image: url("assets/images/roadmap-bg-aero.png");margin: 0px auto 0px auto;position: absolute;top: auto;bottom: -50px;left: 0;box-shadow: none;right: 0;text-align: center;}
  .roadmap-1:before{content:"Q3 2021";}
  .roadmap-2:before{content:"Q4 2021";}
  .roadmap-3:before{content:"Q1 2022";}
  .roadmap-4:before{content:"Q3 2022";}
  .roadmap-5:before{content:"Q4 2022";}
  /* roadmap ends */
  .main-content p, .main-content li{text-align: inherit;font-size: 15px;line-height: 1.6em;}
  .betbnb-center-logo{
    width: 150px !important;
    margin: 30px 0;
  }
  .tokenmics-row img {
    max-width: 70%;
}
.eco-list-item span {
  min-height: 40px;
  min-width: 40px;
  margin-right: 2px;
}
.eco-rightside .eco-list-item span{
  margin-right: 2px;
}
.eco-list-item img {
  max-width: 30px;
  max-height: 30px;
}
.inr-greybx-cont  .form-inline .form-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-left: 0;
}
.form-inline .form-check-input {
  position: relative;
  flex-shrink: 0;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.custom-margin{
  margin-top: 18px;
}
.inner-greybox-bordered .inr-greybx-cont{
  min-height: auto;
}
.inner-greybox-bordered .inr-greybx-cont{
  padding: 13px;
}
.tokenmics-labels p {
  font-size: 11px;
}
.tokenmics-labels .tkm-team {
  right: 14%;
  top: 9%;
}
.tokenmics-labels .tkm-public {
  right: -1%;
  top: 27%;
}
.tokenmics-labels .tkm-liquidity {
  right: 4%;
  bottom: 17%;
}
.tokenmics-labels .tkm-private {
  bottom: 0%;
}
.tokenmics-labels .tkm-marketing {
  bottom: 16%;
  left: 17px;
}
.tokenmics-labels .tkm-ecosystem {
  left: -3%;
}
.tokenmics-labels .tkm-community {
  left:50%;
  top:3%;
}
.tokenmics-labels .tkm-listing {
  left: 1%;
  top: 12%;
}
.tokenmics-labels .tkm-staking {
  left:2%;
  top: -2%;
}
.tokenmics-labels p span {
  margin-left: 5px;
}
.react-date-picker__calendar{
  width: 100% !important;
}
.MuiAppBar-positionFixed{
  z-index: 9999 !important;
}
#ecosystem, #roadmap, #tokenmics, #tiers {
  background-size: cover;
  background-position: 4% 100%;
}
#roadmap {
  padding-bottom: 50px;
}
}
@media(max-width:600px){
  .history_modal .modal-dialog {top: 63px;}
}
@media (min-width: 576px) {
  .modal-dialog {max-width: 410px;}
}
@media(max-width:400px){
  .d-mob{display: block !important;}
}
@media(max-width:400px){
  .small-btn {margin-left: 6px;}
}
/* ======================== Light mode css ===================== */
.card_arrow {
  position: relative;
  width: 209px;
  margin:24px auto;
  z-index: 9;
}
.light .banner p {
  color: #ffffff;
}
.light h2,
.light .footer a i {
  color: #000000;
}
.light .navbar-collapse {
  background: #fff;
}
.light section:not(:last-child):after{
  display: none;
}
.light .yellow-text,.light .navbar-top a i{
  color: #f2842b !important;
}
.light .dropdown.connect-wallet-dropdown .btn {
  background: #ffffff!important;
  color: #000;
}
.light .dropdown.connect-wallet-dropdown .dropdown-menu {
  background-color: #fff;
}
.light .dropdown.connect-wallet-dropdown .dropdown-menu .dropdown-item {
  color: #000;
}
.light .dropdown.connect-wallet-dropdown .dropdown-divider {
  border-top: 1px solid #e5e5e5;
}
.light .dropdown.connect-wallet-dropdown .dropdown-item:focus, 
.light .dropdown.connect-wallet-dropdown .dropdown-item:hover {
  background-color: #e7e7e7;
}
.light .MuiListItem-root.Mui-selected,
.light .MuiListItem-root.Mui-selected:hover, 
.light .MuiListItem-button:hover {
  background-color: #e7e7e7 !important;
}

.light .main-content{
	color:#000 !important;
}
.light .text-yellow{
  color: #f2842b !important;
}
.light #roadmap {
    background: #fff;
}
.light .tier-cards .card{
	background-color: rgb(0 0 0 / 70%);
}
.light #contactus{
    background: #ffffff;
    box-shadow: 0px 4px 15px #0000002b;
}


/* Staking */
.custom_tab.custom_tab_style2 .MuiTabs-flexContainer {
  border:0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #2876FB !important;
}
.custom_tab.custom_tab_style2 .MuiButtonBase-root.Mui-selected {
  background-image: linear-gradient(10deg, #0e58e3 0%, #1061fb 100%);
  -webkit-box-shadow:0px 0px 0px 5px rgba(246,220,52,0.1);
  -moz-box-shadow: 0px 0px 0px 5px rgba(246,220,52,0.1);
  box-shadow: 0px 0px 0px 5px rgba(246,220,52,0.1);
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #ffffff;
}
.custom_tab.custom_tab_style2 .MuiButtonBase-root {
  max-width:200px;
}
.stake-card {
    background-color:#ffffff;
    border-radius: 12px;
    color: #000000;
    box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #2876FB;
    height: 100%;
}
.stake-card:hover {
     outline: 2px solid #fefbf5;
    outline-offset: 2px;
    transform: scale(1.03);
    outline-color: transparent;
}
.card-list-item {
  border-bottom:1px solid #e9e9e9;
}
.card-list-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
}
.label-name {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.card-list-item:last-child {
  border:0;
}
.gray-txt {
  color:#4a4a4a;
  font-size: 12px;
}
.active-color {
  color: #2a6aff;
}
.custom_stepper .MuiStepLabel-horizontal {
  display: flex;
  flex-direction: column;
}
.custom_stepper .MuiStepIcon-root.Mui-completed, 
.custom_stepper .MuiStepIcon-root.Mui-active {
  color: #2a6aff !important;
}
.custom_stepper .MuiSvgIcon-root {
  font-size: 40px;
  color:#a2a5b6;
}
.custom_stepper .MuiStepLabel-label {
  color:#2876FB;
  padding-top: 10px;
}

.custom_stepper .MuiStepLabel-label.Mui-active, .Mui-completed{
  color: #2a6aff !important;
}
.custom_stepper .MuiStepLabel-iconContainer, .MuiStep-root {
  padding: 0;
}
.custom_stepper .MuiStepConnector-line {
  margin-top: -16px;
    margin-left: -38px;
    margin-right: -35px;
}
.lighting-icon {
  min-width:60px;
  text-align: center;
}
.lighting-icon i {
  font-size: 50px;
  color: #0e58e3;
}
.custom_tab.custom_tab_style2 .MuiButton-textPrimary {
  background-image: linear-gradient(10deg, #0e58e3 0%, #1061fb 100%);
    border: 1px #ffffff solid !important;
    border-radius: 20px;
    color: #fff !important;
    padding-left:0px !important;
    padding-right:0px !important;
    box-shadow: 1px 0 2px #ff1baf5d;
    max-width: 120px;
    font-size: 12px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fw-sbold {
  font-weight: 600;
}
.fw-ssbold {
  font-weight: 500;
}
.btn-xs {
  padding: 3px;
  min-height: 20px !important;
}
.color-gray {
  color: #555555;
}
.success-txt {
  font-size: 40px;
  font-weight: 600;
  color: #1A9845;
}
.token-txt {
  text-decoration: underline;
  font-size: 12px;
}
.max-btn {
  max-width: 60px !important;
}
.lhs-side-view {
  position: absolute;
  left: 0;
  top: 0;
}
.rhs-side-view {
  position: absolute;
  right: 0;
  top: 0;
}
.staking-wrapper h1 {
  color: #2876FB;
  font-weight: 600;
}

.title-bg {
  background-image: url(./assets/images/heading-bg.png);
  background-repeat: no-repeat;
  height: 103px;
  text-align: center;
  width: 564px;
  padding-top: 22px;
  color: #fff;
  font-weight: bold;
  font-size: 36px;
}
.basino-txt-wrap {
  background-image: url(./assets/images/basino-text.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  height:155px;
  text-align: center;
  width:100%;
  padding-top: 22px;
  color: #fff;
  font-weight: bold;
  font-size: 36px;
}
.circle-img-1 {
  width: 100px;
  margin-top: 250px;
  height:50px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  z-index: 1;
  animation-name: rotate;
  animation-duration:20s;
  transform: skew(15deg, 15deg);
  animation-iteration-count: infinite;
}

@keyframes rotate{
  from{ transform: rotate(0deg); }
  to{ transform: rotate(360deg); }
}
.circle-img-1 img {
  max-width: 100%;
  margin-bottom: -100px;
  margin-left: -100px;
}
.circle-img-2 {
  width: 440px;
 margin-top: -65px;}
.circle-img-2 img {
  max-width: 100%;
}
.required {
  color: red;
}

.scroll-to-btn {
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.scroll-to-btn:hover i {
  color:#5ddbff;
}

.scroll-to-btn.visible {
  opacity: 1;
}
.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  background-color:#1A9845 !important;
  margin-top: 100px;
  font-size: 16px;
}
@media screen and (min-width:1200px) {
  .staking-wrapper {
    max-width: 1280px;
    margin: 0 auto;
  }
  .tokenmics-container{
    width: 700px;
  }
}

@media(max-width:767px) {
  .banner .row{
    min-height: 100% !important;
  } 
}

@media(max-width:575px) {
  .custom_stepper .MuiSvgIcon-root {
    font-size: 25px !important;
  }
  .custom_stepper .MuiStepLabel-label {
    font-size: 7px !important;
  }
  .custom_stepper .MuiStepConnector-line {
    margin-top: -11px !important;
  }
  .title-bg {
    height: 90px;
    font-size: 12px;
    width: 300px;
    padding-top: 16px;
    color: #fff;
    font-weight: bold;
    background-size: contain;
  }
  .lhs-side-view, .rhs-side-view {
    display: none;
  }
}
.banner-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:9999;
  background-color:#155de1;/* Adjust the alpha value for transparency */
}

.spinner-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:9999;
}

.spinner-wrap {
  position: relative;
  width: 150px; /* Adjust according to your loader circle size */
  height: 150px; /* Adjust according to your loader circle size */
  border-radius: 50%;
  border: 1px solid #ffffff; /* Adjust color and transparency */
  border-left-color: #00257b;
  animation: spin 5s linear infinite;
}

.spinner-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:120px; /* Adjust according to your logo size */
  height: auto;
  z-index:9999;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fa-blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
.fa-blink {
animation: fa-blink 2s linear infinite;
}